import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import { CheckBox as RNECheckBox } from 'react-native-elements';

const CheckBox = class CheckBox extends Component {

  constructor(props) {
    super(props);

    this.inputRef = null;

    this._handleOnChange = this._handleOnChange.bind(this)
  }

  _handleOnChange(evt) {
    this.props.onChange(evt, this.props.name);
  }

  render() {
    
    const {
      containerStyle,
      error,
      ...rest
    } = this.props;

    return (
        <RNECheckBox
          containerStyle={error ? styles.error : containerStyle}
          {...rest}
        />
    );
  }

}

const styles = StyleSheet.create({
  error: {
    backgroundColor: '#FFFFFF',
    borderWidth: 2,
    borderColor: 'red',
    marginLeft: 0
  }
})

export default CheckBox;