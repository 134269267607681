// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/HelveticaNeue-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Helvetica-Condensed-BlackObl.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/HelveticaNeue.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Helvetica-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/HelveticaNeueBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/SFProDisplay-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./fonts/SFProText-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-family: "helvetica-neue-medium";
}

@font-face {
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-family: "helvetica-condensed";
}

@font-face {
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  font-family: "helvetica-neue-regular";
}

@font-face {
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  font-family: "helvetica-regular";
}

@font-face {
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
  font-family: "helvetica-neue-bold";
}

@font-face {
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
  font-family: "sf-pro-display-bold";
}

@font-face {
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
  font-family: "sf-pro-text-regular";
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,4CAA4C;EAC5C,oCAAoC;AACtC;;AAEA;EACE,4CAAoD;EACpD,kCAAkC;AACpC;;AAEA;EACE,4CAAqC;EACrC,qCAAqC;AACvC;;AAEA;EACE,4CAAyC;EACzC,gCAAgC;AAClC;;AAEA;EACE,4CAAyC;EACzC,kCAAkC;AACpC;;AAEA;EACE,4CAAyC;EACzC,kCAAkC;AACpC;;AAEA;EACE,4CAAyC;EACzC,kCAAkC;AACpC","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n@font-face {\n  src: url('./fonts/HelveticaNeue-Medium.ttf');\n  font-family: \"helvetica-neue-medium\";\n}\n\n@font-face {\n  src: url('./fonts/Helvetica-Condensed-BlackObl.otf');\n  font-family: \"helvetica-condensed\";\n}\n\n@font-face {\n  src: url('./fonts/HelveticaNeue.ttf');\n  font-family: \"helvetica-neue-regular\";\n}\n\n@font-face {\n  src: url(\"./fonts/Helvetica-Regular.ttf\");\n  font-family: \"helvetica-regular\";\n}\n\n@font-face {\n  src: url(\"./fonts/HelveticaNeueBold.ttf\");\n  font-family: \"helvetica-neue-bold\";\n}\n\n@font-face {\n  src: url(\"./fonts/SFProDisplay-Bold.ttf\");\n  font-family: \"sf-pro-display-bold\";\n}\n\n@font-face {\n  src: url(\"./fonts/SFProText-Regular.ttf\");\n  font-family: \"sf-pro-text-regular\";\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
