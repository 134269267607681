import React, { Component, useState } from 'react';
import { Dimensions, Linking, ScrollView, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import LinearGradient from 'react-native-web-linear-gradient';
import { Avatar, Button, ListItem } from 'react-native-elements';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import { parseQueryStringParams } from '../utilities/utilities';

import DesktopLanding from '../components/DesktopLanding';
import MobileLanding from '../components/MobileLanding';


import { getCampaign } from '../services/CampaignService';
import { getUser } from '../services/UserService';
import { getMembership } from '../services/MembershipService';

import moment from 'moment';

const { height, width } = Dimensions.get('window');

function _formatProducts(products) {
  return products.reduce((total, product) => {

    product.unitsSold = 0;

    total[product.id] = product;

    return total;
  }, {});
}

function isExpired(campaign) {

  if (!campaign) {
    return false;
  }

  // const endDateTime = moment(campaign.end);
  // const currentDateTime = moment().milliseconds(0).toISOString();

  // return moment(currentDateTime).isAfter(endDateTime);

  return campaign.state === 'COMPLETE' || campaign.state === 'ARCHIVED';
}

class LandingScreen extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      quantity: 1,
      user: null,
      campaign: null,
      products: {},
      purchaseType: 'product',
      donationAmount: 0,
      canChargeDonationProcessorFee: false,
    }

    this._loadData = this._loadData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePurchaseTypeChange = this.handlePurchaseTypeChange.bind(this);
    this.handleSetDonationAmount = this.handleSetDonationAmount.bind(this);
  }

  async componentDidMount() {
    await this._loadData();
  }

  async _loadData() {
    const query = this.props.location.search;
    let params;

    if (query) {
      params = parseQueryStringParams(query);
    }

    try {

      if (!params?.userId || !params?.campaignId) {
        throw new Error("Missing Parameters: Unable to load data");
      }

      const userReq = getUser(params.userId);
      const campaignReq = getCampaign(params.campaignId);
      const membershipReq = getMembership(params.userId, params.campaignId);

      const [user, campaign, membership] = await Promise.all([userReq, campaignReq, membershipReq]);

      this.setState({
        user: user.data,
        campaign: campaign.data,
        membership: membership.data,
        platform: params.platform,
        products: _formatProducts(campaign.data.products)
      });

    } catch(error) {
      console.log("ERROR LOADING DATA IN LANDING", error);
    }
  }

  // handleChange(evt, name) {
  //   this.setState({
  //     [name]: evt.nativeEvent.text
  //   });
  // }

  handlePurchaseTypeChange (purchaseType) {
    this.setState({ purchaseType })
  }

  handleSetDonationAmount (amount) {
    this.setState({ donationAmount: amount})
  }

  handleChange(cb, name, type, val) {
    return (evt) => {
      const { unitsSold, products, didNotify } = this.state;
      let value;

      switch(type) {
        case 'input':
          value = evt.nativeEvent.text;
          break;
        case 'dropdown':
          value = evt
          break;
        case 'increment':
          let productsToInc = { ...products };
          productsToInc[name].unitsSold += 1;
          value = productsToInc;
          name = 'products';
          break;
        case 'decrement':
          let productsToDec = { ...products };
          productsToDec[name].unitsSold = productsToDec[name].unitsSold === 0 ? 0 : productsToDec[name].unitsSold -= 1;
          value = productsToDec;
          name = 'products';
          break;
        case 'button':
          value = val;
          break;
        case 'check':
          value = !didNotify
          break;
        case 'checkbox':
          value = !val
          break;
        default:
          value = '';
      }

      this.setState({ [name] : value }, () => {});
    }
  }

  handleSubmit() {
    this.props.history.push({
      pathname: '/checkout',
      state: {
        ...this.state
      }
    });
  }

  render() {
    const { campaign, quantity, user } = this.state;

    return (
      <>
        <MediaQuery maxDeviceWidth={480}>
          <MobileLanding
            campaign={campaign}
            user={user}
            onSubmit={this.handleSubmit}
            onChange={this.handleChange}
            onPurchaseTypeChange={this.handlePurchaseTypeChange}
            onSetDonationAmount={this.handleSetDonationAmount}
            isExpired={isExpired}
            state={this.state}
          />
        </MediaQuery>
        <MediaQuery minDeviceWidth={481}>
          <DesktopLanding
            campaign={campaign}
            user={user}
            onSubmit={this.handleSubmit}
            onChange={this.handleChange}
            onPurchaseTypeChange={this.handlePurchaseTypeChange}
            isExpired={isExpired}
            state={this.state}
          />
        </MediaQuery>
      </>
    )

    // return (
    //   <>
    //     <ScrollView style={styles.container}>
    //       <LinearGradient
    //         style={styles.cardContainer}
    //         colors={['#0077B3', '#00A5FF', '#1BC7AE']}
    //         start={{ x: 1, y: 1 }}
    //         locations={[0, 0.4992, 1]}
    //         end={{ x: 0, y: 0}}
    //       >
    //         <View style={{width: '100%'}}>
    //           <Text style={{color: 'white', fontSize: 26, lineHeight: 64, letterSpacing: 'normal', fontFamily: 'helvetica-neue-bold'}}>{campaign?.name}</Text>
    //         </View>
    //         <View style={{justifyContent: 'flex-end', borderRadius: 10, overflow: 'hidden'}}>
    //           {
    //             campaign?.products && campaign?.products?.length > 0 &&
    //             <ScaledImage
    //               source={{ uri: campaign?.products[0]?.image }}
    //               width={width * 0.9}
    //               style={styles.image}
    //             />
    //           }
    //           {
    //             !campaign?.products || campaign?.products?.length === 0 &&
    //             <View></View>
    //           }
    //         </View>
    //       </LinearGradient>
    //       <View style={styles.contentContainer}>
    //         <View style={styles.titleContainer}>
    //           <Avatar
    //             renderPlaceholderContent={<View style={{ backgroundColor: '#999999'}}></View>}
    //             source={{uri: campaign?.organization_logo}}
    //             size="xlarge"
    //             containerStyle={{width: width * 0.20, height: width * 0.20, marginBottom: 20}}
    //           />
    //           <Text style={{fontSize: 20, fontWeight: '700', fontFamily: 'helvetica-neue-medium'}}>{`Purchase ${campaign?.products[0]?.group}`}</Text>
    //           <ListItem
    //               key={1}
    //               leftAvatar={
    //                 <Avatar
    //                   rounded
    //                   renderPlaceholderContent={
    //                     <LinearGradient
    //                       style={{height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center'}}
    //                       colors={['#15B9BC', '#00A5FF', '#0077B3']}
    //                       start={{ x: 1, y: 1 }}
    //                       locations={[0, 0.4992, 1]}
    //                       end={{ x: 0, y: 0}}
    //                     >
    //                       <Text style={{fontSize: 20, fontWeight: '700', color: '#FFFFFF', fontFamily: 'helvetica-condensed', paddingRight: 5}}>
    //                         {`${user?.firstName.slice(0,1)}${user?.lastName.slice(0,1)}`}
    //                       </Text>
    //                     </LinearGradient>
    //                   }
    //                   source={{uri: user?.image}}
    //                   size="xlarge"
    //                   containerStyle={{width: width * 0.20, height: width * 0.20}}
    //                 />
    //               }
    //               title={`${user?.firstName} ${user?.lastName}`}
    //               subtitle={
    //                 <View style={{}}>
    //                   <Text style={{fontSize: 15, color: '#666666', letterSpacing: -0.36, lineHeight: 22, fontFamily: 'helvetica-regular'}}>{campaign?.name}</Text>
    //                 </View>
    //               }
    //               titleStyle={[styles.title, {paddingBottom: 5}]}
    //               containerStyle={{paddingHorizontal: 0, marginTop: 5}}
    //               contentContainerStyle={{paddingLeft: width * 0.05}}
    //             />
    //             <View>
    //               <Text style={styles.description}>
    //                 {`Select the ${campaign?.products[0]?.group?.toLowerCase()} flavors you would like and the quantities for each. ${user?.firstName} will contact you to give you your ${campaign?.products[0]?.group?.toLowerCase()} when they are ready. Your purchase will directly support this fundraising campaign. Thank you!`}
    //               </Text>
    //             </View>
    //             {/* <View style={{paddingVertical: width * 0.05}}>
    //               <Text style={{fontSize: 16, lineHeight: 19, letterSpacing: 0.23, color: '#000000', fontFamily: 'helvetica-neue-bold'}}>{`Price: $${campaign?.unitPrice} Each`}</Text>
    //             </View> */}
    //             { !isExpired(campaign) &&
    //               <>
    //                 <View style={{justifyContent: 'center', paddingTop: 20, marginBottom: 30}}>
    //                   <View style={{width: '100%', justifyContent: 'space-evenly'}}>
    //                     <Text style={{fontSize: 17, lineHeight: 22, letterSpacing: -0.41, fontFamily: 'helvetica-neue-bold', color: '#000000'}}>
    //                       {true ? 'Unit Quantities' : 'Unit Quantity'}
    //                     </Text>
    //                     {
    //                       campaign?.products.map((product) => {
    //                         const { id, name, unitsSold } = product
        
    //                         return (
    //                           <ProductCard
    //                             handleChange={this.handleChange}
    //                             name={name}
    //                             quantity={unitsSold}
    //                             id={id}
    //                             key={id}
    //                             titleStyle={{ fontFamily: 'helvetica-neue-bold'}}
    //                             containerStyle={{ paddingVertical: 20 }}
    //                           />
    //                         )
    //                       })
    //                     }
    //                   </View>
    //                 </View>
    //                 <Button
    //                   title={`Purchase ${campaign?.products[0]?.group}`}
    //                   color="#00A9FF"
    //                   containerStyle={styles.btnContainer}
    //                   buttonStyle={styles.btnStyle}
    //                   titleStyle={styles.btnLabel}
    //                   onPress={this.handleSubmit}
    //                 />
    //               </>
    //             }
    //             {
    //               isExpired(this.state.campaign) &&
    //               <View style={{flexDirection: 'row', justifyContent: 'center', paddingTop: width * 0.05, height: 100, alignItems: 'center'}}>
    //                 <Text style={{fontSize: 24, fontWeight: 'bold'}}>Campaign closed</Text>
    //               </View>
    //             }
    //         </View>
    //         <View style={{ justifyContent: 'center', paddingTop: 60, width: '100%', alignItems: 'center'}}>
    //           <ScaledImage
    //             source={{ uri: 'https://americanyouth.com/wp-content/uploads/2013/11/American-Youth-Menu-Logo.png' }}
    //             height={18}
    //           />
    //           <Text style={{ fontFamily: 'helvetica-neue-medium', fontSize: 15, lineHeight: 18, color: '#000000', paddingTop: 15 }}>Need a fundraiser? Contact us today at</Text>
    //           <TouchableWithoutFeedback onPress={() => Linking.openURL('https://americanyouth.com')}>
    //             <Text style={{ fontFamily: 'helvetica-neue-medium', fontSize: 15, lineHeight: 18, color: '#00A5FF', textDecorationLine: "underline", paddingTop: 5 }}>americanyouth.com</Text>
    //           </TouchableWithoutFeedback>
    //         </View>
    //       </View>
    //     </ScrollView>
    //   </>
    // );
  }
}

const styles = StyleSheet.create({
  container: {
    height,
    width,
    backgroundColor: '#FFFFFF'
  },
  cardContainer: {
    display: 'inline-block',
    width,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: width * 0.05,
    paddingBottom: width * 0.1,
    paddingTop: width * 0.025
  },
  image: {
    alignSelf: 'flex-end'
  },
  contentContainer: {
    paddingHorizontal: width * 0.05,
    paddingVertical: width * 0.1
  },
  titleContainer: {

  },
  title: {
    fontFamily: 'helvetica-neue-bold',
    fontSize: 16,
    letterSpacing: 0.23,
    lineHeight: 19,
    color: '#000000',
    fontWeight: '600'
  },
  description: {
    fontSize: 15,
    lineHeight: 22,
    color: '#666666',
    fontFamily: 'helvetica-regular'
  },
  btnContainer: {
    backgroundColor: "#00A9FF"
  },
  btnStyle: {
    backgroundColor: "#00A9FF",
    paddingVertical: 15
  },
  btnLabel: {
    fontSize: 15,
    letterSpacing: -0.36,
    lineHeight: 22,
    fontFamily: 'sf-pro-text-regular'
  }
})

export default withRouter(LandingScreen);
