import React, { useState } from 'react';
import { Dimensions, Linking, ScrollView, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import LinearGradient from 'react-native-web-linear-gradient';
import { Avatar, Button, ListItem } from 'react-native-elements';
import MediaQuery from 'react-responsive';

import CheckBox from '../components/CheckBox';
import Input from '../components/Input';
import ProductCard from '../components/ProductCard';
import ScaledImage from '../components/ScaledImage';

const { height, width } = Dimensions.get('window');

const MobileLanding = (props) => {

  const {
    campaign,
    user,
    onSubmit: handleSubmit,
    onChange: handleChange,
    onPurchaseTypeChange: handlePurchaseTypeChange,
    onSetDonationAmount: handleSetDonationAmount,
    isExpired,
    state
  } = props;

  const { canChargeDonationProcessorFee, donationAmount, purchaseType, products } = state;

  const donationOptions = ['35', '50', '100', '250', '500']

  const hasSelectedProducts = Object.keys(products).reduce((total, productKey) => {
    const product = products[productKey];
    const { unitsSold } = product;

    total += unitsSold;

    return total;
  }, 0)

  const canSubmit = (purchaseType === "donation" && donationAmount > 0) || (purchaseType === "product" && hasSelectedProducts)

  return (
    <>
      <ScrollView style={styles.container}>
        <LinearGradient
          style={styles.cardContainer}
          colors={['#0077B3', '#00A5FF', '#1BC7AE']}
          start={{ x: 1, y: 1 }}
          locations={[0, 0.4992, 1]}
          end={{ x: 0, y: 0}}
        >
          <View style={{width: '100%'}}>
            <Text style={{color: 'white', fontSize: 26, lineHeight: 64, letterSpacing: 'normal', fontFamily: 'helvetica-neue-bold'}}>{campaign?.name}</Text>
          </View>
          <View style={{justifyContent: 'flex-end', borderRadius: 10, overflow: 'hidden'}}>
            {
              campaign?.products && campaign?.products?.length > 0 &&
              <ScaledImage
                source={{ uri: campaign?.products[0]?.image }}
                width={width * 0.9}
                style={styles.image}
              />
            }
            {
              !campaign?.products || campaign?.products?.length === 0 &&
              <View></View>
            }
          </View>
        </LinearGradient>
        <View style={styles.contentContainer}>
          <View style={styles.titleContainer}>
            <Avatar
              renderPlaceholderContent={<View style={{ backgroundColor: '#999999'}}></View>}
              source={{uri: campaign?.organization_logo}}
              size="xlarge"
              containerStyle={{width: width * 0.20, height: width * 0.20, marginBottom: 20}}
            />
            <Text style={{fontSize: 20, fontWeight: '700', fontFamily: 'helvetica-neue-medium'}}>{`Purchase ${campaign?.products[0]?.group}`}</Text>
            <ListItem
                key={1}
                leftAvatar={
                  <Avatar
                    rounded
                    renderPlaceholderContent={
                      <LinearGradient
                        style={{height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center'}}
                        colors={['#15B9BC', '#00A5FF', '#0077B3']}
                        start={{ x: 1, y: 1 }}
                        locations={[0, 0.4992, 1]}
                        end={{ x: 0, y: 0}}
                      >
                        <Text style={{fontSize: 20, fontWeight: '700', color: '#FFFFFF', fontFamily: 'helvetica-condensed', paddingRight: 5}}>
                          {`${user?.firstName.slice(0,1)}${user?.lastName.slice(0,1)}`}
                        </Text>
                      </LinearGradient>
                    }
                    source={{uri: user?.image}}
                    size="xlarge"
                    containerStyle={{width: width * 0.20, height: width * 0.20}}
                  />
                }
                title={`${user?.firstName} ${user?.lastName}`}
                subtitle={
                  <View style={{}}>
                    <Text style={{fontSize: 15, color: '#666666', letterSpacing: -0.36, lineHeight: 22, fontFamily: 'helvetica-regular'}}>{campaign?.name}</Text>
                  </View>
                }
                titleStyle={[styles.title, {paddingBottom: 5}]}
                containerStyle={{paddingHorizontal: 0, marginTop: 5}}
                contentContainerStyle={{paddingLeft: width * 0.05}}
              />
              <View>
                <Text style={styles.description}>
                  {`Select the ${campaign?.products[0]?.group?.toLowerCase()} you would like and the quantities for each. ${user?.firstName} will contact you to give you your ${campaign?.products[0]?.group?.toLowerCase()} when they are ready. Your purchase will directly support this fundraising campaign. Thank you!`}
                </Text>
              </View>
              {/* <View style={{paddingVertical: width * 0.05}}>
                <Text style={{fontSize: 16, lineHeight: 19, letterSpacing: 0.23, color: '#000000', fontFamily: 'helvetica-neue-bold'}}>{`Price: $${campaign?.unitPrice} Each`}</Text>
              </View> */}
              { !isExpired(campaign) &&
                <>
                  {
                    purchaseType === 'product' &&
                    <View style={{justifyContent: 'center', paddingTop: 20, marginBottom: 30}}>
                      <View style={{width: '100%', justifyContent: 'space-evenly'}}>
                        <Text style={{fontSize: 17, lineHeight: 22, letterSpacing: -0.41, fontFamily: 'helvetica-neue-bold', color: '#000000'}}>
                          {true ? 'Unit Quantities' : 'Unit Quantity'}
                        </Text>
                        {
                          campaign?.products.map((product) => {
                            const { id, name, unitsSold } = product
        
                            return (
                              <ProductCard
                                handleChange={handleChange}
                                name={name}
                                quantity={unitsSold}
                                id={id}
                                key={id}
                                titleStyle={{ fontFamily: 'helvetica-neue-bold'}}
                                containerStyle={{ paddingVertical: 20 }}
                              />
                            )
                          })
                        }
                      </View>
                    </View>
                  }
                  {
                    purchaseType === 'donation' &&
                    <View style={{justifyContent: 'center', paddingTop: 20, marginBottom: 30}}>
                      <View style={{width: '100%', justifyContent: 'space-evenly', gap: 20}}>
                        <Text style={{fontSize: 17, lineHeight: 22, letterSpacing: -0.41, fontFamily: 'helvetica-neue-bold', color: '#000000'}}>
                          Please Select
                        </Text>
                        <View style={{flexDirection: 'row', gap: 15, flexWrap: "wrap", justifyContent: 'flex-start'}}>
                          {
                            donationOptions.map((option) => (
                              <Button
                                title={`$${option}`}
                                buttonStyle={[styles.btnStyle, { backgroundColor: donationAmount === option ? 'rgb(18, 207, 45)' : 'rgb(233,233,233)'}]}
                                containerStyle={[styles.btnContainer, {width: '30%', backgroundColor: donationAmount === option ? 'rgb(18, 207, 45)' : 'rgb(233,233,233)'}]}
                                titleStyle={[styles.btnLabel, { color: donationAmount === option ? '#FFF' : 'rgb(102,102,102)', fontFamily: 'helvetica-neue-bold'}]}
                                onPress={() => {
                                  handleChange(null, 'donationAmount', 'button', option)()
                                }}
                              />
                            ))
                          }
                        </View>
                        <Text style={{fontSize: 17, lineHeight: 22, letterSpacing: -0.41, fontFamily: 'helvetica-neue-bold', color: '#000000'}}>
                          or Input Amount
                        </Text>
                        <View>
                          <Input
                            name="donationAmount"
                            value={donationAmount}
                            placeholder={"0.00"}
                            containerStyle={styles.inputContainer}
                            inputStyle={{...(donationAmount !== 0 && { color: 'rgb(51, 51, 51)'})}}
                            onChange={(value, name)=>{
                              console.log("VALUE", value)
                              handleChange(null, 'donationAmount', 'input')(value)
                            }}
                            leftIcon={
                              <View style={{paddingRight: 15, marginLeft: -15}}>
                                <Text style={[styles.btnLabel, { color: 'rgb(179,179,179)', fontFamily: 'helvetica-neue-bold'}]}>$</Text>
                              </View>
                            }
                            rightIcon={
                              <View>
                                <Text style={[styles.btnLabel, { color: 'rgb(179,179,179)', fontFamily: 'helvetica-neue-bold'}]}>USD</Text>
                              </View>
                            }
                          />
                          <View style={[styles.notifyContainer, styles.inputContainer]}>
                            <CheckBox
                              checked={canChargeDonationProcessorFee}
                              checkedIcon="check"
                              checkedColor="#FFFFFF"
                              uncheckedColor="rgb(230,230,230)"
                              uncheckedIcon="close"
                              iconType="antdesign"
                              containerStyle={canChargeDonationProcessorFee ? styles.isNotified : styles.isNotNotified}
                              size={8}
                              onPress={() => {
                                handleChange(null, 'canChargeDonationProcessorFee', 'checkbox', canChargeDonationProcessorFee)()
                              }}
                            />
                            <Text style={{...styles.notifyText, ...(canChargeDonationProcessorFee && { color: '#000'})}}>{`Please charge me an additional $${((Number(donationAmount) * 0.029) + 0.3).toFixed(2)} to cover the credit card processing fees`}</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  }
                  <View style={{flexDirection: 'row', gap: 10}}>
                    {
                      purchaseType === 'product' && campaign?.acceptsDonations &&
                      <Button
                        title={'Just Donate'}
                        color="#00A9FF"
                        containerStyle={[styles.btnContainer, {flex: 1, backgroundColor: 'rgb(233,233,233)'}]}
                        buttonStyle={[styles.btnStyle, {backgroundColor: 'rgb(233,233,233)'}]}
                        titleStyle={[styles.btnLabel, { color: '#000' }]}
                        onPress={() => handlePurchaseTypeChange('donation')}
                      />
                    }
                    {
                      purchaseType === 'donation' &&
                      <Button
                        title={'Go Back'}
                        color="#00A9FF"
                        containerStyle={[styles.btnContainer, {flex: 1, backgroundColor: 'rgb(233,233,233)'}]}
                        buttonStyle={[styles.btnStyle, {backgroundColor: 'rgb(233,233,233)'}]}
                        titleStyle={[styles.btnLabel, { color: '#000' }]}
                        onPress={() => handlePurchaseTypeChange('product')}
                      />
                    }
                    {
                      purchaseType === 'product' &&
                      <Button
                        title={`Purchase ${campaign?.products[0]?.group}`}
                        color="#00A9FF"
                        containerStyle={[styles.btnContainer, {flex: 2, backgroundColor: hasSelectedProducts ? '#00A9FF' : 'rgb(233,233,233)'}]}
                        buttonStyle={[styles.btnStyle, {backgroundColor: hasSelectedProducts ? '#00A9FF' : 'rgb(233,233,233)'}]}
                        titleStyle={[styles.btnLabel, {color: hasSelectedProducts ? '#FFF' : 'rgb(179,179,179)'}]}
                        onPress={() => {
                          if (canSubmit) {
                            handleSubmit()
                          }
                        }}
                      />
                    }
                    {
                      purchaseType === 'donation' &&
                      <Button
                        title={`Donate ${donationAmount > 0 ? `$${donationAmount > 0 ? donationAmount: ''}` : ''}`}
                        color="#00A9FF"
                        containerStyle={[styles.btnContainer, {flex: 2, backgroundColor: donationAmount > 0 ? 'rgb(18, 207, 45)' : 'rgb(233,233,233)'}]}
                        buttonStyle={[styles.btnStyle, {backgroundColor: donationAmount > 0 ? 'rgb(18, 207, 45)' : 'rgb(233,233,233)'}]}
                        titleStyle={[styles.btnLabel, { color: donationAmount > 0 ? '#FFF' : 'rgb(179,179,179)'}]}
                        onPress={() => {
                          console.log("CAN SUBMIT", canSubmit)
                          if (canSubmit) {
                            handleSubmit()
                          }
                        }}
                      />
                    }
                  </View>
                </>
              }
              {
                isExpired(campaign) &&
                <View style={{flexDirection: 'row', justifyContent: 'center', paddingTop: width * 0.05, height: 100, alignItems: 'center'}}>
                  <Text style={{fontSize: 24, fontWeight: 'bold'}}>Campaign closed</Text>
                </View>
              }
          </View>
          <View style={{ justifyContent: 'center', paddingTop: 60, width: '100%', alignItems: 'center'}}>
            <ScaledImage
              source={{ uri: 'https://americanyouth.com/wp-content/uploads/2013/11/American-Youth-Menu-Logo.png' }}
              height={18}
            />
            <Text style={{ fontFamily: 'helvetica-neue-medium', fontSize: 15, lineHeight: 18, color: '#000000', paddingTop: 15 }}>Need a fundraiser? Contact us today at</Text>
            <TouchableWithoutFeedback onPress={() => Linking.openURL('https://americanyouth.com')}>
              <Text style={{ fontFamily: 'helvetica-neue-medium', fontSize: 15, lineHeight: 18, color: '#00A5FF', textDecorationLine: "underline", paddingTop: 5 }}>americanyouth.com</Text>
            </TouchableWithoutFeedback>
          </View>
        </View>
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    height,
    width,
    backgroundColor: '#FFFFFF'
  },
  cardContainer: {
    display: 'inline-block',
    width,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: width * 0.05,
    paddingBottom: width * 0.1,
    paddingTop: width * 0.025
  },
  image: {
    alignSelf: 'flex-end'
  },
  contentContainer: {
    paddingHorizontal: width * 0.05,
    paddingVertical: width * 0.1
  },
  titleContainer: {

  },
  title: {
    fontFamily: 'helvetica-neue-bold',
    fontSize: 16,
    letterSpacing: 0.23,
    lineHeight: 19,
    color: '#000000',
    fontWeight: '600'
  },
  description: {
    fontSize: 15,
    lineHeight: 22,
    color: '#666666',
    fontFamily: 'helvetica-regular'
  },
  btnContainer: {
    backgroundColor: "#00A9FF"
  },
  btnStyle: {
    backgroundColor: "#00A9FF",
    paddingVertical: 15
  },
  btnLabel: {
    fontSize: 15,
    letterSpacing: -0.36,
    lineHeight: 22,
    fontFamily: 'sf-pro-text-regular'
  },
  inputContainer: {
    marginVertical: 15
  },
  notifyContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center'
  },
  isNotified: {
    backgroundColor: 'rgb(18, 207, 45)',
    borderWidth: 1,
    borderColor: 'rgb(18, 207, 45)',
    marginLeft: 0
  },
  isNotNotified: {
    backgroundColor: 'rgb(230,230,230)',
    borderWidth: 1,
    borderColor: 'rgb(230,230,230)',
    marginLeft: 0
  },
  notifyText: {
    color: 'rgb(122,122,122)'
  }
});

export default MobileLanding;