import React from 'react';
import { Dimensions, ScrollView, StyleSheet, View } from 'react-native';
import LinearGradient from 'react-native-web-linear-gradient';
import { Formik } from 'formik';
import { Button } from 'react-native-elements';

import { PROCESSING_FEE } from '../constants';

const { height, width } = Dimensions.get('window');

function _calculatePrice(paymentType, products) {
  const total = Object.keys(products).reduce((total, key) => {
    const { price, unitsSold } = products[key];
    total += unitsSold * price;

    switch(paymentType) {
      case 'cash':
      case 'check':
        return total;
      case 'credit':
        return total + (PROCESSING_FEE * unitsSold);
      default:
        return total;
    }

  }, 0);

  return total;
}

function _calculateDonationAmount(donationAmount, includeProcessorFee) {
  const processorFee = includeProcessorFee ? ((Number(donationAmount) * 0.029) + 0.3) : 0;
  return (Number(donationAmount) + processorFee).toFixed(2);
}

function _setSubmitBtnTitle(page, data) {
  switch(page) {
    case 0:
      return 'Proceed to Payment';
    case 1:
      return 'Review Order';
    case 2:
      return `Charge $${
        data.purchaseType === 'donation' ?
        _calculateDonationAmount(data.donationAmount, data.canChargeDonationProcessorFee) :
        _calculatePrice(data.paymentType, data.productsSold)
      }`;
  }
}

class FormWizard extends React.Component {
  static Page = ({ children }) => children;
  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      values: props.initialValues,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialValues !== this.props.initialValues) {
      this.setState({
        values: this.props.initialValues
      })
    }
  }

  next(values) {
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }));
  }

  prev() {
    this.setState(state => ({
      page: Math.max(state.page - 1, 0),
    }));
  }

  async handleSubmit(values, bag) {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isConfirmationPage = page === React.Children.count(children) - 2;

    try {
      if (isConfirmationPage) {
        await onSubmit(values, bag);
      }
      bag.setTouched({});
      bag.setSubmitting(false);
      this.next(values);
    } catch (err) {

    }
  };

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isFirstPage = page === 0;
    const isLastPage = page === React.Children.count(children) - 1;
    
    return (
      <Formik
        initialValues={values}
        enableReinitialize={true}
        validationSchema={this.props.validationSchemas[page]}
        onSubmit={this.handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, handleSubmit, isSubmitting }) => {
          return (
            <View style={styles.formContainer}>
              {activePage}
              <View style={styles.btnGroup}>
                { !isFirstPage && !isLastPage &&
                  <Button
                    title='Back'
                    containerStyle={[styles.btnContainer, styles.btnCompact]}
                    buttonStyle={[styles.btnStyle, {backgroundColor: '#FFFFFF'}]}
                    titleStyle={[styles.btnLabel, {color: '#666666'}]}
                    onPress={this.prev}
                  />
                }
                { !isLastPage &&
                  <Button
                    title={_setSubmitBtnTitle(page, { paymentType: values.paymentType, productsSold: values.productsSold, purchaseType: values.purchaseType, donationAmount: values.donationAmount, canChargeDonationProcessorFee: values.canChargeDonationProcessorFee })}
                    containerStyle={!isFirstPage && !isLastPage ? [styles.btnContainer, styles.btnCompact] : styles.btnContainer}
                    buttonStyle={styles.btnStyle}
                    ViewComponent={LinearGradient}
                    linearGradientProps={{
                      colors: ['#0077B3','#00A5FF','#15B9BC'],
                      start: { x: 0, y: 0 },
                      locations: [0, 0.49917236, 1],
                      end: { x: 1, y: 1 },
                      cornerRadius: 2
                    }}
                    disabled={isLastPage && isSubmitting}
                    loading={isSubmitting}
                    titleStyle={styles.btnLabel}
                    onPress={() => {
                      handleSubmit(values);
                    }}
                  />
                }
              </View>
            </View>
          );
        }}
      </Formik>
    );
  }
}

const styles = StyleSheet.create({
  formContainer: {
    paddingHorizontal: width * 0.05,
    minHeight: height * 0.85
  },
  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: -1
  },
  btnContainer: {
    borderRadius: 2,
    paddingVertical: 11,
    width: '100%'
  },
  btnStyle: {
    paddingVertical: 15
  },
  btnLabel: {
    fontSize: 15,
    color: '#FFFFFF',
    letterSpacing: -0.36,
    lineHeight: 22,
    fontFamily: 'helvetica-regular'
  },
  btnCompact: {
    width: '46%'
  }
});

export default FormWizard;