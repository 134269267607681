import React from 'react';
import { TouchableWithoutFeedback, StyleSheet, Text, View } from 'react-native';
import { Icon } from 'react-native-elements';

const QuantityPicker = (props) => {

  const {
    handleChange,
    quantity = 0,
    name,
    containerStyle = {}
  } = props;

  return (
    <View style={{flexDirection: 'row', justifyContent: 'space-evenly', backgroundColor: '#EEEEEE', paddingVertical: 10, paddingHorizontal: 5, alignItems: 'center', width: '30%', borderRadius: 5, ...containerStyle}}>
      {/* <Icon
        name="minus"
        type="antdesign"
        color="#00A9FF"
        size={28}
        // onPress={handleChange(null, 'unitsSold', 'decrement')}
        onPress={handleChange(null, name, 'decrement')}
      /> */}
      <TouchableWithoutFeedback onPress={handleChange(null, name, 'decrement')}>
        <View>
          <Text style={{color: '#00A9FF', fontSize: 28}}>-</Text>
        </View>
      </TouchableWithoutFeedback>
      <Text style={{color: '#333333', fontSize: 17, lineHeight: 22, letterSpacing: -0.41, fontFamily: 'helvetica-neue-bold'}}>{quantity}</Text>
      <TouchableWithoutFeedback onPress={handleChange(null, name, 'increment')}>
        <View>
          <Text style={{color: '#00A9FF', fontSize: 28}}>+</Text>
        </View>
      </TouchableWithoutFeedback>
      {/* <Icon
        name="plus"
        type="antdesign"
        color="#00A9FF"
        size={28}
        // onPress={handleChange(null, 'unitsSold', 'increment')}
        onPress={handleChange(null, name, 'increment')}
      /> */}
    </View>
  );
}

// const styles = StyleSheet.create({
//   container: {
//     justifyContent: 'center',
//     alignItems: 'center'
//   },
//   circle: {
//     height: 100,
//     width: 100
//   },
//   textContainer: {
//     width: '100%', 
//     height: '100%',
//     position: 'absolute',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   text: {
//     color: '#333333',
//     fontSize: 15
//   }
// });

export default QuantityPicker;